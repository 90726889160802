import React, { FC, memo, useCallback } from 'react';
import IconSvgPayment from '../../../payment/IconSvgPayment';

import { IAiPurchases } from '../../../../global/types';
import { getActions, withGlobal } from '../../../../global';
import { useTranslation } from 'react-i18next';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import Button from '../../../ui/Button';
import useLastCallback from '../../../../hooks/useLastCallback';
import { MiddleColumnContent } from '../../../../types';
import ChatBot from '../../../left/main/ChatBot';
import Loading from '../../../ui/Loading';
import { historyPushState } from '../../../../util/routing';
import SearchInput from '../../../ui/SearchInput';
import classNames from 'classnames';

import TextBackground from '../../../../assets/payment/text-bg-transactions.jpg';
import ImgBackground from '../../../../assets/payment/image-bg.jpg';

import DCCategory from '../../../../assets/icons/DC.png';
import MarvelCategory from '../../../../assets/icons/Marvel.png';
import SportCategory from '../../../../assets/icons/Sport.png';
import HealthCategory from '../../../../assets/icons/Health.png';

import './AiSpace.scss';
import useFlag from '../../../../hooks/useFlag';

type OwnProps = {
  isActive?: boolean;
  onReset: () => void;
};

type StateProps = {
  aiPurchases?: IAiPurchases;
  botsList?: string[];
};

const AiSpaceMain: FC<StateProps & OwnProps> = ({
  aiPurchases,
  botsList,
  isActive,
  onReset,
}) => {
  const { setAiPurchases, setMiddleScreen } = getActions();
  const { text_total = 0, img_total = 0 } = aiPurchases || {};
  const { t } = useTranslation();
  const [showAllCategory, openAllCategory] = useFlag();
  const handlePaymentAi = useLastCallback(() => {
    historyPushState({
      data: {
        middleScreen: MiddleColumnContent.Settings,
      },
    });
    setMiddleScreen({ screen: MiddleColumnContent.PaymentAi });
  });

  useEffectOnce(() => {
    setAiPurchases();
  });

  return (
    <div className='settings-container ai-space'>
      {/* <SearchInput value='' inputId='CountrySearch' onChange={() => true} />
      <div
        className={classNames('filter-group', { 'show-all': showAllCategory })}
      >
        <button
          type='button'
          className={classNames('Button without-icon active selected')}
        >
          All
        </button>
        <button type='button' className={classNames('Button')}>
          <i
            className='icon'
            style={{ backgroundImage: `url(${DCCategory})` }}
          />
          DC
        </button>
        <button type='button' className={classNames('Button')}>
          <i
            className='icon'
            style={{ backgroundImage: `url(${MarvelCategory})` }}
          />
          Marvel
        </button>
        <button type='button' className={classNames('Button')}>
          <i
            className='icon'
            style={{ backgroundImage: `url(${SportCategory})` }}
          />
          Sports
        </button>
        <button type='button' className={classNames('Button')}>
          <i
            className='icon'
            style={{ backgroundImage: `url(${HealthCategory})` }}
          />
          Health
        </button>

        <button type='button' className={classNames('Button all-button')}>
          More
          <i className='css-icon-down' />
        </button>
      </div> */}
      <div className='ai-banner'>
        <h3>Al Space: Your Gateway to Advanced Al bots</h3>
        <div className='description'>
          Discover a world of cutting-edge artificial intelligence at Al Space,
          your destination for state-of-the-art Al bots. It offers an extensive
          collection of specialized Al bots, each meticulously crafted to
          address diverse needs across multiple categories.
        </div>
        <dl>
          <dd>
            <span
              className='Avatar size-small no-photo'
              style={{ backgroundImage: `url(${TextBackground})` }}
            >
              <IconSvgPayment name='text' w='20' h='20' />
            </span>
            <span>{text_total}</span>
            {t('AI.Chat_prompts')}
          </dd>
          <dd>
            <span
              className='Avatar size-small no-photo'
              style={{ backgroundImage: `url(${ImgBackground})` }}
            >
              <IconSvgPayment name='image' w='20' h='20' />
            </span>
            <span>{img_total}</span>
            {t('AI.Image_prompts')}
          </dd>
        </dl>
        <Button fullWidth size='smaller' onClick={handlePaymentAi}>
          Buy AI pack
        </Button>
      </div>
      <h3>AI Bots</h3>
      <div className='bots-list'>
        {botsList ? (
          botsList.length && botsList.map((botId) => <ChatBot chatId={botId} />)
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      aiPurchases,
      chats: { listIds },
    } = global;
    const botsList = listIds.bots;
    return {
      aiPurchases,
      botsList,
    };
  })(AiSpaceMain)
);
